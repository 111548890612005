import { useState, useEffect } from "react";
import { Route, Routes, Switch } from 'react-router-dom';
import JsonData from "./data/data.json";

import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Navigation } from "./components/navigation";
import Home from "./Home";
import { Footer } from "./components/footer";
import Footerdetail from "./components/Footerdetail";

import { Privacypolicy } from "./components/pages/privacypolicy";



export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});


const App = () => {
  

  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
    
      <div className='social'>
        {/*
        <div>
          <a className="whatsapp" href="https://api.whatsapp.com/send?phone=9118005712225" target="_blank">
            <i className="fa fa-whatsapp"></i>
          </a>
        </div>  
        */}
        <div className="pop">
          <a className="phonescreen" href="tel:18005712225 ">
            <img style={{ border: "0" }} src="img/banners/quickenquiry.png" alt="callus@18005712225 " />
          </a>
        </div>
      </div>
      <Navigation />

      <Routes>
        <Route exact path="/" element={<Home />} />
        
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        
        

        </Routes>
      
      <Footerdetail data={landingPageData.Contact} />
      <Footer data={landingPageData.Footer} />
    </div>

  );
};


export default App;

