import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Pricetag } from "./components/pricetag/pricetag"

import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

import { Helmet } from "react-helmet";
//import MediaCard from "./components/future/mediacard"

import { green } from '@mui/material/colors';
import Crous2 from "./components/Crous2";
import Chooseus from "./components/chooseus";


import Certifications from "./components/Certifications";
import { Products } from "./components/products/Products"
import Productcrousalmain from "./components/products/Productcrousalmain";
import { Brandlink } from "./components/brandlink";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

{/*
const fabStyle = {
  position: 'absolute',
  bottom: 16,
  right: 16,
};
 const fabGreenStyle = {
    color: 'common.white',
    bgcolor: green[500],
    '&:hover': {
      bgcolor: green[600],
    },
  };

*/}


const Home = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);


  return (
    <div>
      <Helmet>
        <meta name="keywords" content="RO Service Center, RO Service Center Near me , Water Purifier Services, Water Purifier Service Near me , Kent RO Service Center, Aquaguard RO Service Center, Eureka Forbes RO Service Center, AO Smith RO Service Center, Bluestar RO Service Center, Aqua Fresh RO Service Center, Livpure, RO Service Center, Pureit RO Service Center, Havells RO Service Center, LG RO Service Center, Whirlpool  RO Service Center, Marq RO Service Center, Aqua Grand RO Service Center, Kuchina RO Service Center, Bluestar Water Purifier Service Center, Aqua Fresh Water Purifier Service Center, Livpure Water Purifier Service Center, Pureit Water Purifier Service Center, Havells Water Purifier Service Center, LG Water Purifier Service Center, Whirlpool Water Purifier Service Center, Marq Water Purifier Service Center, Aqua Grand Water Purifier Service Center, Kuchina Water Purifier Service Center" />
        <meta name="author" content="Wappotix pvt ltd" />
        <meta name="description" content="Aquag RO Service in India We are India's leading RO water purifier repair and maintanance service provider of all brands like Bluestar, Pureit, Livpure, Aqua Guard, Aqua Fresh etc. RO repair service in India" ></meta>
      </Helmet>
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      
      {/*<Crous /> 
        <Crous2 /> 
        <Caurousel />
        <Header data={landingPageData.Header} />
        for whatspp web use link href="https://wa.me/9060591201"

        <Productcrousalmain  />
        <Certifications />
        <Testimonials data={landingPageData.Testimonials} />
        <Brandlink />
      */}

      
      <About data={landingPageData.About} />
      <Chooseus />
      <Pricetag data={landingPageData.Pricetag} />


      <Services data={landingPageData.Services} />
      
      <Contact data={landingPageData.Contact} />
      
      
    </div>
  );

}


export default Home;
